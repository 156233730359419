.image-view {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../assets//images//placeholder.png);
}

.image-view-img {
  display: block;
}
