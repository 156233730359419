@import './contact-step';
@import './unit-details-step';
@import './docu-sign-step';
@import './billing-step';

.booking-steps {
  margin: 15px 80px 80px;

  .number-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    .vl {
      display: block;
      border-left: 2px solid #dddfe1;
      margin-top: 30px;
      flex: 1;
    }
  }

  .step {
    display: flex;
    margin-bottom: 30px;

    .step-number {
      display: flex;
      align-items: center;
      text-align: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $step-background;
      // position: relative;

      span {
        width: 40px;
        height: 40px;
        margin: auto;
        font-weight: bold;
        font-size: 15px;
        color: $primary-white;
        margin-top: 8px;
      }
    }

    .vl {
      display: none;
    }

    .step-content {
      margin-left: 80px;
      width: 100%;
      .step-name {
        h2 {
          @include GraphikBold(22px, 150%);
          color: $step-background;
          margin: 0;
        }
      }

      .details {
        font-size: 14px;
        color: $secondary-cyan-blue;
        font-weight: normal;
      }

      .content {
        .content-main {
          min-height: 200px;
          .loader {
            margin-top: 80px;
          }
        }
      }
    }

    &.active {
      margin-top: 30px;
      .step-number {
        background-color: $secondary-cyan-blue;
        span {
          color: $secondary-red;
        }
      }
      .vl {
        display: block;
      }

      .step-name {
        h2 {
          color: $secondary-cyan-blue;
          margin: 0;
        }
      }
    }

    &.finished {
      margin-top: 30px;
      .step-number {
        background-color: $secondary-red;
      }
      .step-name {
        h2 {
          color: $secondary-cyan-blue;
          margin: 0;
        }
      }
    }

    .navigation-btns {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      .booking-navigation-btn {
        margin-bottom: 34px;
        button {
          width: 227px;
          height: 60px;
          border-radius: 10px;
          font-weight: 600;
          font-size: 18px;
          color: $secondary-cyan-blue;
          margin-left: 30px;
        }

        &.back {
          button {
            background-color: transparent;
            border: 2px solid $secondary-cyan-blue;
          }
        }

        &.continue {
          button {
            color: $primary-white;
            background-color: $secondary-red;
          }
        }
      }
    }
  }
}
@include mq('desktop-wide') {
  .booking-steps {
    margin: 15px 80px 80px;
    .step {
      .step-content {
        margin-left: 70px;
        margin-right: 50px;
      }
    }
  }
}
@include mq('desktop') {
  .booking-steps {
    margin: 15px 80px 80px;
    .step {
      .step-content {
        margin-left: 80px;
        margin-right: 30px;
      }
    }
  }
}

@include mq('tablet-wide') {
  .booking-steps {
    margin: 15px;
    .step {
      .step-content {
        margin-left: 50px;
        margin-right: 30px;
        .step-container {
          &.first {
            padding: 10px 20px 40px 20px;
            .booking-form {
              flex-direction: column;

              .right-side,
              .left-side {
                width: 100%;
              }
            }
          }
        }
      }
      .navigation-btns {
        .booking-navigation-btn {
          button {
            width: 150px;
            height: 50px;
          }
        }
      }
      &.active {
        .step-number {
          .vl {
            top: 80px;
            left: 16px;

            &.one {
              height: 860px;
            }

            &.two {
              height: 1260px;
            }

            &.three {
              height: 470px;
            }

            &.fourth {
              height: 1250px;
            }
          }
        }
      }
    }
  }
}

@include mq('tablet') {
  .booking-steps {
    .navigation-btns {
      .booking-navigation-btn {
        button {
          margin-left: 0 !important;
        }
      }
      .continue {
        margin-left: 5px !important;
      }
      justify-content: center !important;
    }
    .step {
      .step-content {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}

@include mq('phablet') {
  .booking-steps {
    .step-number {
      display: none !important;
    }
    .step {
      .step-content {
        .name-inputs,
        .adress-inputs {
          flex-direction: column;
          .input,
          .custom-select-container {
            width: 100%;
          }
        }
      }
    }
  }
}

@include mq('phone') {
  .booking-steps {
    margin: 5px;
    .step-content {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
