.template {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .main {
    display: flex;
    flex-direction: 'row';
    flex: 1;
    .content {
      flex: 1;
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
