.sidebar {
  background-color: $secondary-red;
  width: 70px;
  min-width: 70px;
  .sidebar-logo {
    text-align: center;
    margin-top: 16px;
    // svg {
    //   margin: 16px;
    // }

    img {
      width: 38px;
    }
  }
  .sidebar-dialog {
    margin-top: 536px;
    margin-left: 10px;
    svg {
      cursor: pointer;
    }
    position: relative;
    .dialog-window {
      position: absolute;
      transition: all 0.3s;
      top: 0;
      left: 65px;
      background-color: $primary-white;
      width: 217px;
      height: 55px;
      box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.18);
      border-radius: 5px;
      padding: 9px 7px 0 15px;
      color: $dialog-text;
      z-index: 99999;
      @include OpenSans(14px, 19px, 400);
      span {
        color: $secondary-cyan-blue;
        @include OpenSans(14px, 19px);
      }
      .arrow-left {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        position: absolute;
        border-right: 8px solid $primary-white;
        left: -8px;
        top: 18px;
      }
      svg {
        position: absolute;
        top: -7px;
        right: -6px;
      }
    }
  }
  @include mq('phone-wide') {
    display: none;
  }
}
