.plan-card {
    display: flex;
    flex-direction: column;
    border: 2px solid $light-blue-background;
    padding: 49px 12px;
    text-align: center;
    border-radius: 4px;
  
    &.center-card {
      border-left: none;
      border-right: none;
    }
  
    &.selected {
      box-shadow: $shadow;
      border: 2px solid $active-color;
  
      .prepay {
        background-color: $prepay-active-background;
        color: $secondary-red;
        border: none;
      }
    }
  
    &:hover {
      border: 2px solid $secondary-cyan-blue;
    }
  
    .card-title {
      @include OpenSans(18px, 150%, bold);
      margin-bottom: 21px;
    }
  
    .price {
      @include OpenSans(20px, 150%, bold);
      margin-bottom: 26px;
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        font-weight: normal;
        line-height: 10px;
      }
    }
  
    .prepay {
      @include Graphik(12px);
      width: auto;
      margin: auto;
      border-radius: 8px;
      border: 1px solid $input-border;
      padding: 5px 14px;
      margin-bottom: 47px;
      text-transform: uppercase;
    }
  
    .descripton {
      margin-top: auto;
      line-height: 1;
      @include Graphik();
      line-height: 16px;
      font-size: 12px;
  
      i {
        @include OpenSans(11px, 130%, normal);
  
        sup {
          @include OpenSans(11px, 130%, normal);
          color: $active-color;
          top: 0;
        }
      }
    }
  }
  
  @include mq("tablet-wide") {
    .plan-card {
      margin-top: 10px;
    }
  }
  