.location-info-container {
  background-color: $light-blue-background;
  padding: 40px 79px 40px 74px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  .back-button {
    position: absolute;
    left: 20px;
    top: 28px;
  }
  .location-image {
    width: 480px;
    height: 320px;
  }
  .location-info-content {
    margin-top: 20px;
    width: 460px;
    .info-card:first-of-type {
      margin-top: 24px;
      margin-bottom: 6px;
    }
    .location-name {
      color: $secondary-cyan-blue;
      @include GraphikBold(40px, 130%);
      margin: 0;
      margin-bottom: 12px;
    }
    .location-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
    }
    .buttons-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 35px;
      a:first-child {
        margin-left: 0;
      }
      a {
        margin-left: 10px;
      }

      @media (min-width: 768px) and (max-width: 1025px) {
        // border: 1px solid red;
        flex-wrap: wrap;
        a:last-child {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
}

.get-directions {
  button {
    padding-left: 0;
    padding-right: 0;
  }
}
