@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/Graphik-Regular.woff2');
  font-display: swap;
}
@font-face {
  font-family: 'GraphikBold';
  src: url('../assets/fonts/Graphik-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('../assets/fonts/OpenSans-SemiBold.woff2');
  font-display: swap;
}
