.payment-info {
  padding: 24px 40px 0 40px;
  background-color: $primary-white;
  flex: 1;
  margin-right: 20px;
  .payment-title {
    @include GraphikBold(18px, 150%);
    color: $secondary-cyan-blue;
    margin-bottom: 14px;
  }
  .payment-label {
    @include OpenSans(14px, 150%);
    opacity: 0.4;
    color: $secondary-cyan-blue;
    margin-bottom: 20px;
    margin-top: 16px;
  }
  .payment-select-box {
    width: 100%;
    height: 32px;
    display: flex;
    border-radius: 4px;
    background-color: $light-blue-background;
    .p-select-option {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .option-label {
        margin-left: 10px;
        @include Graphik(14px, 15px);
        color: $secondary-cyan-blue;
        opacity: 0.4;
        margin-top: 2px;
      }
      &.selected {
        background-color: $primary-white;
        border-radius: 4px;
        box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.18);
        .option-label {
          color: $secondary-cyan-blue;
          opacity: 1;
        }
      }
    }
  }
  .payment-container {
    .select-options {
      text-transform: capitalize;
    }
    .input {
      input::placeholder {
        @include OpenSans(14px, 19px);
        color: $secondary-cyan-blue;
        opacity: 0.4;
      }
    }
    .credit-card-form {
      padding-bottom: 76px;
    }
    .ach-form {
      padding-bottom: 10px;
      .select-options {
        height: auto !important;
      }
    }
    .credit-card-form,
    .ach-form {
      .two-inputs {
        display: flex;
        .input:first-child {
          margin-right: 20px;
        }
      }
      .input,
      .custom-select-container {
        margin-bottom: 20px;
      }
    }
  }
  @include mq('desktop') {
    padding: 24px 20px 0 20px;
  }
  @include mq('tablet-wide') {
    width: 300px;
    .two-inputs {
      flex-direction: column;
    }
  }
}
