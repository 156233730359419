.data-picker-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .react-datepicker__navigation--next {
    background: url(../../assets/images/picker-right.png) no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    outline: none;
  }

  .react-datepicker__navigation--previous {
    background: url(../../assets/images/picker-left.png) no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    outline: none;
  }

  .calendar-icon {
    position: absolute;
    top: 57%;
    right: 5%;
    z-index: 1;
  }
  .label {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include OpenSans(14px, 19px, normal);

    .info-icon {
      margin-left: 7px;
    }
  }

  .info {
    @include OpenSans(14px, 19px, normal);
    position: absolute;
    background-color: $primary-white;
    border-radius: 4px;
    bottom: 80px;
    box-shadow: $shadow;
    padding: 5px 20px;
    text-align: left;

    &:after {
      content: "▲";
      top: 45.3px;
      position: absolute;
      transform: rotate(178deg);
      left: 135px;
      text-shadow: 0px -1px 4px rgba(0, 0, 0, 0.18);
      color: $primary-white;
      font-size: 18px;
    }
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .date-picker {
        width: 208px;
        height: 40px;
        border: 1px solid $input-border;
        border-radius: 4px;
        padding: 17px;
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      .react-datepicker {
        @include Graphik;
        padding: 5px;

        .react-datepicker__navigation {
          top: 16px;
          margin-left: 15px;
          margin-right: 9px;
        }

        .react-datepicker__month-container {
          .react-datepicker__header {
            background-color: white;
            border-bottom: none;
            .react-datepicker__current-month {
              color: $secondary-cyan-blue;
              padding-bottom: 10px;
            }

            .react-datepicker__day-names {
              color: $secondary-cyan-blue;
              .react-datepicker__day-name {
                @include OpenSans(12px, 14px, normal);
                margin: 0 7px;
                text-transform: uppercase;
              }
            }
          }

          .react-datepicker__month {
            .react-datepicker__week {
              div {
                @include OpenSans(14px);
                color: $secondary-cyan-blue;
                margin: 2px 7px;
              }

              .react-datepicker__day--selected {
                background-color: $secondary-red;
              }

              .react-datepicker__day--outside-month,
              .react-datepicker__day--disabled {
                opacity: 0.4;
              }
            }
          }
        }
      }
    }
  }
}
