.location-card {
  display: flex;
  line-height: 24px;
  padding: 10px;
  background-color: $primary-white;
  box-shadow: $shadow;
  margin: 10px 0;
  border: 1px solid transparent;
  color: $secondary-cyan-blue;
  cursor: pointer;
  // max-height: 120px;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.hovered {
    border: 1px solid $active-color;
  }

  .card-img {
    min-width: 96px;
    min-height: 96px;
    width: 96px;
    height: 96px;
    border-radius: 4px;
    margin-right: 18px;
  }

  .card-info {
    .card-title {
      @include Graphik(18px, 150%, 600);
    }

    .location-coord {
      margin-top: 15px;
      @include OpenSans(14px, 150%, normal);
    }

    .card-number {
      margin-top: 5px;
      a {
        color: $secondary-cyan-blue;
        @include OpenSans(14px, 150%, 600);
        text-decoration-line: underline;
      }
    }

    .location-telephone {
      a {
        text-decoration: none;
        color: $secondary-cyan-blue;
      }
    }
  }
  @include mq('tablet-small') {
    .card-info {
      .card-title {
        white-space: pre-wrap;
      }
    }
  }
}
