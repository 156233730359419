.rating-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-right: 2px;
  }
  .rating-label {
    margin-left: 12px;
    @include Graphik(14px, 15px, 600);
    color: $secondary-cyan-blue;
  }
}
