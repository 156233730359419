.locations-spinner {
  margin-top: 40% !important;
}
.search-spinner {
  margin-top: 100px !important;
}
.locations-page {
  .not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $secondary-cyan-blue-opacity;
    margin-top: 30px;

    .not-found-icon {
      width: 100px;
      height: auto;
      margin-bottom: 15px;
      path {
        fill: $secondary-cyan-blue-opacity;
      }
    }

    .info-text {
      text-align: center;
    }
  }
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 90px);
  position: relative;
  background-color: $light-blue-background-with-oacity;
  .back-button {
    position: absolute;
    top: 28px;
    left: 20px;
  }

  .locations-list {
    width: 40%;
    padding: 20px;
    padding-top: 63px;

    .list-title {
      @include GraphikBold(22px, 150%);
      h3 {
        color: $secondary-cyan-blue;
      }
    }
  }

  .location-map {
    width: 60%;
    height: auto;
    background-color: $secondary-cyan-blue;
    margin-top: 30px;
    div:first-child {
      height: 100%;
      width: 100%;
    }
    .google-map {
      position: relative;
      height: 100%;
      width: 100%;
      .gm-bundled-control {
        left: 0 !important;
        padding-bottom: 100px;
      }
      div {
        div {
          div {
            div {
              div:nth-child(13) {
                div {
                  position: relative !important;
                  width: 40px;
                }
              }
              div:nth-child(16) {
                div:first-child {
                  height: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include mq('desktop') {
    .locations-list {
      width: 50%;
      .location-card {
        max-height: 100%;
        white-space: initial;
      }
    }
    .location-map {
      width: 50%;
    }
  }
  @include mq('tablet-wide') {
    .locations-list {
      width: 50%;
      .location-card {
        white-space: initial;
        max-height: 100%;
      }
    }
    .location-map {
      width: 50%;
    }
  }

  @include mq('tablet') {
    flex-direction: column;
    .locations-list {
      width: 100%;
    }
    .location-map {
      width: 100%;
      min-height: 400px;
      flex: 1;
    }
  }
  @include mq('tablet-small') {
    .locations-list {
      .location-card {
        max-height: 100%;
      }
    }
  }
  @include mq('phablet') {
    .locations-list {
      padding: 5px;
      padding-top: 53px;
    }
  }
  @include mq('phone') {
    .location-card {
      white-space: initial;
      max-height: 200px;
    }
  }
  @media (max-width: 520px) {
    .location-card {
      white-space: initial;
    }
  }
}
