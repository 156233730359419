@import './../../node_modules/normalize-scss/sass/normalize/import-now';
@import '../../node_modules/react-datepicker/dist/react-datepicker.css';
@import './fonts';
@import './variables';
@import './mixins';
@import './pages/index';
@import './components/index';

html,
body,
#root,
.App {
  height: auto;
  box-sizing: border-box;
  @include OpenSans;
}
.body {
  -webkit-font-smoothing: antialiased;
}

#avochato-text-us {
  @include mq('phone') {
    width: 90vw !important;
  }
}
