.third-step {
  background-color: $primary-white;
  width: 100%;
  box-sizing: border-box;
  padding-top: 24px;
  margin-top: 30px;
  .docusign-title {
    @include GraphikBold(18px, 150%);
    margin-bottom: 17px;
    color: $secondary-cyan-blue;
    margin-left: 40px;
  }
  .docusign-container {
    background-color: $light-blue-background;
    height: 750px;
    display: flex;
    margin-bottom: 55px;
    margin-left: 40px;
    margin-right: 40px;

    iframe {
      width: 100%;
      border: none;
    }
  }
  .navigation-btns {
    background-color: $primary-background;
    padding-top: 20px;
  }
}

@include mq('tablet') {
  .third-step {
    .docusign-container {
      height: 500px;
      margin-right: 15px;
      margin-left: 15px;
      margin-bottom: 20px;
    }
  }
}

@include mq('phone') {
  .third-step {
    .docusign-container {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
