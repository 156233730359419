.google-map {
  height: 100%;
  width: 100%;
  position: relative;

  @include mq("tablet") {
    max-height: 400px;

    div {
      max-height: 400px;
    }
  }
}
