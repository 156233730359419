.payment-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .payment-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .payment-info {
      width: 100%;
      max-width: 600px;
      min-width: 400px;
      margin-right: 0;
      .credit-card-form {
        padding-bottom: 16px !important;
      }
    }
  }
  .input.error {
    span {
      top: 35px;
    }
  }
  .payment-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    .price-btn {
      button {
        width: 227px;
        height: 60px;
        border-radius: 10px;
        @include Graphik(18px, 20px, 600);
      }
    }
  }
  @include mq("tablet") {
    .payment-content {
      flex-direction: column;
    }
  }
  @include mq("phone") {
    .payment-content {
      .payment-info {
        min-width: none;
      }
    }
  }
}
