.additional-info {
  .red-label {
    @include GraphikBold(14px, 150%);
    color: $secondary-red;
  }
  .additional-text {
    @include OpenSans(14px, 150%, 600);
    color: $secondary-cyan-blue;
  }
  a {
    text-decoration: underline;
  }
}
