/* COLORS */
$light-blue-background: #f1f3f5;
$light-blue-background-with-oacity: #f1f3f5fa;
$primary-white: #fff;
$active-color: #e62222;
$primary-background: #e5e5e5;
$secondary-cyan-blue: #00173c;
$secondary-cyan-blue-opacity: rgba(0, 23, 60, 0.4);
$dialog-text: rgba(0, 23, 60, 0.6);
$secondary-red: #e62222;
$step-background: #a9b1be;
$input-border: #dddfe1;
$error-color: #e62222;
$prepay-active-background: rgba(230, 34, 34, 0.15);
$black: #000000;

/* SHADOW */
$shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.18);
