.final-page {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: $primary-background;
  .back-button {
    margin-top: 28px;
    margin-left: 20px;
    align-self: flex-start;
    margin-bottom: 66px;
  }
  .final-window {
    width: 95%;
    height: 386px;
    background-color: $primary-white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 66px;
    border-radius: 4px;
    svg {
      margin-bottom: 24px;
    }
    h3 {
      margin-bottom: 11px;
      @include GraphikBold(22px, 150%);
      color: $secondary-cyan-blue;
    }
    p {
      margin-bottom: 30px;
      color: $secondary-cyan-blue;
      @include OpenSans(14px, 150%, normal);
    }

    .booking-navigation-btn {
      button {
        width: 227px;
        height: 60px;
        border-radius: 10px;
        font-weight: 600;
        font-size: 18px;
        color: $secondary-cyan-blue;
        margin-left: 30px;
      }

      &.back {
        button {
          background-color: transparent;
          border: 2px solid $secondary-cyan-blue;
        }
      }

      &.continue {
        button {
          background-color: $secondary-red;
        }
      }
    }
  }
  @include mq('tablet') {
    .final-window {
      h3 {
        text-align: center;
      }
      p {
        text-align: center;
      }
      .booking-navigation-btn {
        button {
          margin-left: 0;
        }
      }
    }
  }
}
