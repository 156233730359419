.modal-mask {
  position: fixed;
  z-index: 9999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.53);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wait-list {
  width: 600px;
  background-color: $primary-white;
  position: relative;
  padding: 60px;
  color: $secondary-cyan-blue;
  @include OpenSans(14px, 150%, normal);

  .booking-navigation-btn {
    margin-bottom: 34px;
    button {
      width: 227px;
      height: 60px;
      border-radius: 10px;
      font-weight: 600;
      font-size: 18px;
      color: $secondary-cyan-blue;
      margin-left: 30px;
    }

    &.back {
      button {
        background-color: transparent;
        border: 2px solid $secondary-cyan-blue;
      }
    }

    &.continue {
      button {
        color: $primary-white;
        background-color: $secondary-red;
      }
    }
  }


  .select-options {
    margin-bottom: 20px;
    max-height: none;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 30px;
    font-size: 28px;
    cursor: pointer;
    color: $primary-background;
  }

  .modal-title {
    h3 {
      text-align: center;
      color: $secondary-cyan-blue;
      @include GraphikBold(22px, 150%);
    }
  }

  .modal-info {
    text-align: center;
  }

  .modal-form {
    .input,
    .custom-select-container {
      margin-top: 15px;
    }
    .name-inputs {
      display: flex;
      justify-content: space-between;

      .first-name,
      .last-name {
        width: 47%;
      }
    }

    .submit-btn {
      .submit {
        margin-top: 30px;
        text-align: center;

        button {
          background-color: $secondary-red;
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          padding: 20px 80px;
          color: $secondary-cyan-blue;
        }
      }
    }
  }
  @include mq('tablet') {
    width: 500px;
  }
  @include mq('phone-wide') {
    width: 400px;
    padding: 30px;
  }
  @include mq('phone') {
    width: 350px;
    padding: 20px;
  }
  
}
