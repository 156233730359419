.footer {
  background-color: $light-blue-background;
  display: flex;
  justify-content: space-between;
  padding: 40px 300px 20px 90px;
  align-items: center;
  box-sizing: border-box;

  .right-row,
  .left-row {
    display: flex;
    justify-content: space-between;
    width: 40%;
    align-items: flex-start;
    height: 180px;
  }

  .left-side,
  .center,
  .right-side {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .center {
    i {
      font-style: normal;
      @include OpenSans(14px, 150%, normal);
    }

    .telephones {
      display: flex;
      flex-direction: column;
    }
    .email {
      @include OpenSans(15px, 25px, bold);
      color: $secondary-cyan-blue;
      margin-bottom: 10px;
    }

    a {
      margin: 0;
      color: $secondary-cyan-blue;
      text-decoration: none;

      sub {
        bottom: 0;
      }
    }
  }

  .left-side {
    a {
      color: $secondary-cyan-blue;
      @include OpenSans(15px, 20px, bold);
    }
  }

  .right-side {
    .logos {
      display: flex;
      margin-bottom: 10px;

      a {
        margin-right: 15px;
      }
    }

    p {
      font-weight: bold;
      margin-bottom: 0;
      @include OpenSans(12px, 16px, bold);
    }
  }

  @include mq('desktop') {
    padding: 40px 200px 20px 60px;
  }
  @include mq('tablet-wide') {
    padding: 20px 70px 15px 70px;

    .right-row,
    .left-row {
      flex-direction: column;
      height: auto;
    }

    .center {
      margin-bottom: 50px;
    }

    .logo {
      margin-bottom: 15px;
    }
  }
  @include mq('tablet') {
    padding: 20px 70px 15px 70px;
    .logo {
      p {
        margin-top: 10px;
      }
    }
  }
  @include mq('phablet') {
    padding: 10px 30px 15px 30px;
    .logo {
      svg {
        width: 35px;
        height: 83px;
      }
      p {
        font-size: 6px;
        line-height: 8px;
        margin: 0;
      }
    }

    .center {
      margin-bottom: 30px;
    }

    .left-side,
    .center,
    .right-side {
      a {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  @include mq('phone') {
    .right-row {
      width: auto;
    }
  }
}
