.modal-mask {
  position: fixed;
  z-index: 9999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.53);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-modal {
  width: 600px;
  background-color: $primary-white;
  position: relative;
  padding: 60px;
  color: $secondary-cyan-blue;
  @include OpenSans(14px, 150%, normal);

  label {
    @include GraphikBold();
  }

  .select-options {
    margin-bottom: 20px;
    max-height: none;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 30px;
    font-size: 28px;
    cursor: pointer;
    color: $primary-background;
  }

  .modal-title {
    h3 {
      text-align: center;
      color: $secondary-cyan-blue;
      @include GraphikBold(22px, 150%);
    }
  }

  .modal-info {
    text-align: center;
  }

  .insurance-input {
    width: 100%;
  }

  .name-inputs {
    margin-bottom: 15px;
  }

  .error {
    color: red;
    height: 30px;
  }

  .upload-file-wrapper {
    border: 2px dashed $secondary-cyan-blue;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 15px;

    p {
      font-weight: bold;
      opacity: 0.6;
      margin: 0;
      display: flex;
      flex-direction: row;
      word-break: break-all;
      svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }

    #file {
      display: none;
    }
  }

  .modal-form {
    .submit-btn {
      @media screen and (max-width: 770px){
        flex-direction: column;
        .booking-navigation-btn {
          margin-bottom: 10px;
        }
        .submit {
          button {
            width: 227px;
          }
        }
      }
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .booking-navigation-btn {
        button {
          width: 227px;
          height: 60px;
          border-radius: 10px;
          font-weight: 600;
          font-size: 18px;
          color: $secondary-cyan-blue;
        }
    
        &.back {
          button {
            background-color: transparent;
            border: 2px solid $secondary-cyan-blue;
          }
        }
    
        &.continue {
          button {
            color: $primary-white;
            background-color: $secondary-red;
          }
        }
      }
      .submit {
        text-align: center;

        button {
          background-color: $secondary-red;
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          padding: 20px 80px;
          color: $primary-white;
        }
      }
    }
  }
  @include mq('tablet') {
    width: 500px;
  }
  @include mq('phone-wide') {
    width: 400px;
    padding: 30px;
  }
  @include mq('phone') {
    width: 350px;
    padding: 20px;
  }
  
}
