.back-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 8px;
    @include Graphik(15px, 17px);
    color: $secondary-cyan-blue;
    font-weight: bold;
  }
}
