.units-table {
  .little-letter {
    font-size: 14px;
  }
  .ant-table-tbody > tr > td {
    background-color: $primary-white;
  }
  .ant-table-thead > tr > th {
    background-color: $primary-white;
  }

  .ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
  }

  .ant-table .ant-table-thead > tr > .ant-table-column-has-sorters:hover {
    background: unset;
  }

  .ant-table-wrapper {
    padding: 0 170px 0 100px;
    .ant-table-cell:nth-child(4) {
      width: 132px;
      button {
        width: 132px;
      }
    }
    .ant-table-cell:nth-child(3) {
      width: 224px;
    }
    .ant-table-cell:nth-child(2) {
      width: 240px;
      text-transform: capitalize;
    }
    .ant-table-thead {
      border-collapse: unset;
      tr {
        border-bottom: 2px solid $secondary-cyan-blue;
      }
      .ant-table-column-has-sorters {
        border-bottom: 2px solid $secondary-cyan-blue;
        background-color: $primary-white;
      }
      .ant-table-cell {
        @include OpenSans(14px, 150%, bold);
        color: $secondary-cyan-blue;
        border-bottom: 2px solid $secondary-cyan-blue;
        svg {
          fill: $secondary-cyan-blue;
        }
      }
      .ant-table-column-sorters {
        padding-left: 0;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        @include OpenSans(14px, 150%, normal);
        color: $secondary-cyan-blue;
        padding: 36px 0 36px 0;
      }
      .ant-table-cell:nth-child(3),
      .ant-table-cell:nth-child(1) {
        @include OpenSans(28px, 150%, normal);
      }

      .not-found {
        @include OpenSans(18px, 150%, bold);
        color: $secondary-cyan-blue-opacity;
      }
    }
  }
  .ant-table table {
    border-collapse: unset;
  }
}
