.unit-rental-info {
  width: 420px;
  background-color: $primary-white;
  padding: 24px 30px;
  .unit-details {
    border-bottom: 1px solid $input-border;
    h4 {
      @include GraphikBold(18px, 150%);
      color: $secondary-cyan-blue;
    }
    .unit-size {
      @include OpenSans(28px, 150%, normal);
      margin: 0;
      color: $secondary-cyan-blue;
      span {
        font-size: 12px;
      }
    }
    .unit-address {
      @include OpenSans(14px, 150%, normal);
      margin-bottom: 25px;
      color: $secondary-cyan-blue;
      svg {
        margin-right: 7px;
      }
    }
  }
  .total-line {
    border-top: 1px solid $input-border;
    margin-top: 3px;
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 2px;
  }
  .subtotal-line {
    border-top: 1px solid $input-border;
    margin-top: 15px;
    padding-top: 10px;
  }
  .next-billing-line {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    user-select: none;
    span {
      @include OpenSans(18px, 150%, normal);
      color: $secondary-cyan-blue;
      opacity: 0.4;
    }
  }

  .rental-details {
    padding-bottom: 10px;
    border-bottom: 1px solid $input-border;
    h4 {
      @include GraphikBold(18px, 150%);
      color: $secondary-cyan-blue;
      margin-top: 24px;
      margin-bottom: 4px;
    }
    .rental-price {
      @include OpenSans(18px, 150%, normal);
      color: $secondary-cyan-blue;
      margin-bottom: 20px;
      user-select: none;

      i {
        font-style: normal;
        sub {
          bottom: 0;
          font-size: 80%;
        }
      }
    }
    .rental-btn-group {
      display: flex;
      margin-bottom: 27px;
      align-items: center;
      justify-content: space-between;
      .input {
        width: 58%;
      }
      .apply-btn {
        border: 2px solid $secondary-cyan-blue;
        box-sizing: border-box;
        width: 39%;
        height: 40px;
        border-radius: 4px;
        button {
          width: 100%;
          height: 36px;
          border-radius: 10px;
          @include Graphik(15px, 17px, 600);
          color: $secondary-cyan-blue;
          background-color: transparent;
          padding: 0;
        }
      }
    }
    .coupon {
      position: relative;
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 27px;
      border-radius: 4px;
      align-items: center;
      background: #1cd67d25;
      svg {
        margin-right: 11px;
        margin-left: 11px;
        path {
          fill: #8debbe;
        }
      }
      span {
        @include OpenSans(12px, 16px, normal);
        color: $secondary-cyan-blue;
      }
      .coupon-name {
        font-weight: 600;
        color: $secondary-cyan-blue;
        margin-right: 3px;
      }
      .remove-btn {
        position: absolute;
        top: 12px;
        right: 19px;
        @include Graphik(15px, 17px, 600);
        color: $secondary-cyan-blue;
        cursor: pointer;
      }
    }
    .rental-line {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 11px;
      user-select: none;
      &__border {
        border-top: 1px solid $input-border;
      }
      span {
        @include OpenSans(18px, 150%, normal);
        color: $secondary-cyan-blue;
        opacity: 0.4;
      }
      .description {
        @include OpenSans(12px, 150%, normal);
        color: $secondary-cyan-blue;
        opacity: 0.4;
      }
      .tooltip-btn {
        @include OpenSans(14px, 150%, normal);
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
  .rental-summary {
    margin-top: 18px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    span {
      @include OpenSans(18px, 150%, bold);
      color: $secondary-cyan-blue;
    }
  }

  @include mq("desktop-wide") {
    width: 380px;
  }

  @include mq("desktop") {
    width: 360px;
    padding: 24px 15px;
  }
}
