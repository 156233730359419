.single-location-page {
  width: 100%;
  flex: 1;
  background-color: $primary-white;
  min-height: 100%;

  .rating-container {
    margin-bottom: 20px;
  }

  .ant-carousel {
    width: 480px;
    height: 397px;
  }

  @include mq('desktop') {
    .location-info-container {
      padding: 56px 40px 30px 40px;
      .location-info-content {
        width: 420px;
        .location-name {
          font-size: 36px;
        }
      }
      .location-image {
        width: 420px;
        height: 300px;
      }
    }
    .units-table {
      .ant-table-wrapper {
        padding: 0 100px 0 70px;
      }
    }
    .customer-rewiew-table {
      padding: 30px 100px 0 70px;
    }
  }
  @include mq('tablet-wide') {
    .location-info-container {
      padding: 56px 20px 30px 20px;
      .location-info-content {
        width: 360px;
        .location-name {
          font-size: 32px;
        }
      }
      .location-image {
        width: 400px;
        height: 280px;
      }
    }
    .units-table {
      .ant-table-wrapper {
        padding: 0 40px 0 20px;
        .ant-table-cell:nth-child(1) {
          white-space: nowrap;
        }
        .ant-table-cell:nth-child(2) {
          width: 180px;
        }
        .ant-table-cell:nth-child(3) {
          width: 180px;
        }
      }
    }
    .customer-rewiew-table {
      padding: 30px 40px 0 20px;
    }
  }
  @include mq('tablet') {
    .location-info-container {
      flex-direction: column;
      align-items: center;
      .location-info-content {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    .units-table {
      .ant-table-wrapper {
        .ant-table-cell:nth-child(2) {
          width: 120px;
          padding-left: 20px;
        }
        .ant-table-cell:nth-child(3) {
          width: 120px;
          padding-left: 20px;
        }
        .ant-table-cell:nth-child(4) {
          width: 120px;
          padding-left: 5px;
          button {
            width: 120px;
          }
        }
      }
    }
    .customer-rewiew-table {
      .table-head-raiting {
        padding-right: 10px;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  @include mq('tablet-small') {
    .location-info-container {
      .location-info-content {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .units-table {
      .ant-table-wrapper {
        .ant-table-cell:nth-child(2) {
          width: 60px;
          padding-left: 10px;
        }
        .ant-table-cell:nth-child(3) {
          width: 100px;
          padding-left: 20px;
        }
        .ant-table-cell:nth-child(4) {
          width: 120px;
          button {
            width: 120px;
          }
        }
      }
      .ant-table-wrapper {
        .ant-table-tbody {
          .ant-table-cell:nth-child(3),
          .ant-table-cell:nth-child(1) {
            @include OpenSans(22px, 150%, normal);
          }
        }
      }
    }
    .customer-rewiew-table {
      .ant-table-wrapper {
        .ant-table-cell:nth-child(1) {
          max-width: 200px;
          .table-cell {
            text-align: left;
          }
        }
      }
      .ant-table-cell {
        padding: 5px;
      }
      .ant-table-thead {
        tr {
          .ant-table-cell:first-child {
            font-size: 22px;
          }
        }
      }
    }
  }
  @include mq('phablet') {
    .customer-rewiew-table {
      .table-head-raiting {
        .rating-container {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          .rating-label {
            margin-left: 0;
          }
        }
      }
      .rating-mobile {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .rating-container {
          display: block !important;
          margin-bottom: 0;
          margin-left: 30px;
          margin-top: 5px;
        }
        h4 {
          display: block !important;
          margin-bottom: 0;
        }
      }
      .ant-table-cell:nth-child(1) {
        max-width: none !important;
      }
      .ant-table-cell {
        width: 100%;
        border-bottom: none !important;
        border-top: none !important;
        .rating-container {
          display: none;
        }
        h4 {
          display: none;
        }
      }
      .ant-table-thead {
        tr {
          border-bottom: 2px solid $secondary-cyan-blue;
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
        }
      }
      .ant-table-tbody {
        .ant-table-row-level-0 {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-bottom: 1px solid $secondary-cyan-blue-opacity;
          .table-cell {
            width: 100%;
            padding-right: 0;
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .units-table {
      .ant-table-wrapper {
        padding: 0 10px 0 10px;
      }
    }
    .customer-rewiew-table {
      padding: 30px 10px 0 10px;
    }
  }
  @media (max-width: 500px) {
    .location-info-container {
      padding-bottom: 10px;
      .location-info-content {
        margin-top: 0;
        .location-bottom {
          margin-top: 15px;
        }
      }
      .location-name {
        margin-bottom: 8px;
      }

      .location-info-content {
        .info-card:first-of-type {
          margin-top: 10px;
        }
        .buttons-group {
          margin-top: 20px;
        }
      }
    }
    .units-table {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding-right: 0;
        }
        tr {
          .ant-table-cell {
            font-size: 12px;
          }
        }
      }
      .ant-table-wrapper {
        .ant-table-cell:nth-child(1) {
          min-width: 80px;
        }
        .ant-table-cell:nth-child(2) {
          min-width: 30px;
          padding-left: 5px;
        }
        .ant-table-cell:nth-child(3) {
          width: 80px;
          padding-left: 5px;
        }
        .ant-table-cell:nth-child(4) {
          width: 80px;
          text-align: center;
          button {
            width: 80px;
            padding-left: 5px;
            padding-right: 5px;
            font-size: 13px;
          }
        }
      }
      .ant-table-wrapper {
        .ant-table-tbody {
          .ant-table-cell:nth-child(3),
          .ant-table-cell:nth-child(1) {
            @include OpenSans(22px, 150%, normal);
          }
          .ant-table-cell:nth-child(1) {
            font-size: 18px;
          }
          .ant-table-cell:nth-child(3) {
            font-size: 18px;
          }
          .ant-table-cell:nth-child(2) {
            font-size: 12px;
          }
        }
      }
    }
    .customer-rewiew-table {
      .table-head-raiting {
        .rating-container {
          flex-direction: row;
        }
      }
    }
  }
}

@include mq('tablet') {
  .single-location-page {
    .location-info-container,
    .location-place {
      flex-direction: column;
    }
  }
}
