$breakpoints: (
  'phone': 400px,
  'phone-wide': 480px,
  'phablet': 560px,
  'tablet-small': 640px,
  'tablet': 768px,
  'tablet-wide': 1025px,
  'desktop': 1248px,
  'desktop-wide': 1440px
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin GraphikBold($size: false, $lh: false) {
  font-family: 'GraphikBold';
  font-style: normal;
  @if $size {
    font-size: $size;
  }
  @if $lh {
    line-height: $lh;
  }
}

@mixin Graphik($size: false, $lh: false, $fw: false) {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  @if $size {
    font-size: $size;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $fw {
    font-weight: $fw;
  } @else {
    font-weight: 500;
  }
}

@mixin OpenSans($size: false, $lh: false, $fw: false) {
  font-family: 'Open Sans';
  font-style: normal;
  @if $size {
    font-size: $size;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $fw {
    font-weight: $fw;
  } @else {
    font-weight: bold;
  }
}

@mixin OpenSansSemiBold($size: false, $lh: false) {
  font-family: 'Open Sans SemiBold';
  font-style: normal;
  @if $size {
    font-size: $size;
  }
  @if $lh {
    line-height: $lh;
  }
}

@mixin for-mobile {
  @media (max-width: 768px) {
    @content;
  }
}
