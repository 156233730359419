@use '../mixins.scss' as *;

.booking-page {
  background-color: $primary-background;
  height: 100%;
  .back-button {
    margin-top: 28px;
    margin-left: 20px;
  }
}

@include mq('phone') {
  .booking-page {
    .back-button {
      margin-left: 10px;
    }
  }
}
