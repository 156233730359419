.fourth-step {
  margin-top: 22px;
  .fourth-step-content {
    display: flex;
    justify-content: space-between;

    .rental-btn-group {
      .input {
        span {
          top: 35px;
        }
      }
    }
  }
  @include mq("tablet") {
    .fourth-step-content {
      flex-direction: column;
      .payment-info {
        width: 100%;
        margin-bottom: 20px;
      }
      .unit-rental-info {
        width: 100%;
      }
    }
    .navigation-btns {
      .continue {
        button {
          width: 240px !important;
        }
      }
    }
  }
  @include mq("tablet-small") {
    .navigation-btns {
      .continue {
        button {
          width: 220px !important;
        }
      }
    }
  }
  @include mq("phablet") {
    .fourth-step-content {
      .unit-rental-info {
        max-height: 800px;
        .input {
          width: 150px;
        }
      }
    }
    .navigation-btns {
      flex-direction: column-reverse;
      align-items: center;
      .continue {
        button {
          width: 220px !important;
        }
        margin-bottom: 20px !important;
      }
    }
  }
}
