.button {
  button {
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    @include Graphik(15px, 17px, 600);
  }
}

.book-btn {
  button {
    width: 132px;
    background-color: transparent;
    margin: 0;
    border: 1px solid $active-color;
    padding: 12px 20px 11px 20px;
    text-align: center;
    color: $secondary-red;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    white-space: nowrap;
  }
}
.price-btn {
  button {
    width: 132px;
    background-color: $secondary-red;
    margin: 0;
    border: 1px solid $active-color;
    padding: 12px 20px 11px 20px;
    text-align: center;
    color: $primary-white;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
  }
}

.waitlist-btn {
  button {
    background-color: transparent;
    margin: 0;
    border: 1px solid $secondary-cyan-blue-opacity;
    padding: 12px 16px 11px 15px;
    text-align: center;
    color: $secondary-cyan-blue;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
  }
}

.lds-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid $secondary-cyan-blue;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $secondary-cyan-blue transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
