.info-card {
  .info-card-label {
    @include OpenSans(14px, 150%, 600);
    color: $secondary-cyan-blue;
    -webkit-font-smoothing: antialiased;
    font-weight: 600 !important;
  }
  .info-card-text {
    @include OpenSans(14px, 150%, normal);
    color: $secondary-cyan-blue;
    -webkit-font-smoothing: antialiased;
  }
}
