.step-container {
  margin-top: 20px;

  &.second {
    @include GraphikBold();
    color: $secondary-cyan-blue;
    position: relative;
    box-sizing: border-box;
    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 0;
      margin-top: 20px;
    }
    .unit-details {
      background-color: $primary-white;
      padding: 24px 80px 45px 40px;
      .unit-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .address {
          @include OpenSans(14px, 150%, normal);

          .navigation-icon {
            margin-right: 7px;
          }
        }

        .size {
          @include OpenSans(28px, 1, normal);
          min-width: 180px;
        }
      }
    }

    .payment-plan {
      background-color: $primary-white;
      margin-top: 20px;
      padding: 24px 40px 55px;

      .plans-info {
        margin-top: 20px;
        @include Graphik();
      }

      .payment-cards {
        width: 100%;
        display: flex;
        margin-top: 17px;
        .payment-card {
          flex: 1;
        }
      }
      &.error {
        border: 1px solid $error-color;
      }
    }
  }

  .success-msg, .error-msg {
    font-size: 12px;
    @include OpenSans;
    position: absolute;
    word-break:break-all;
  }

  .error-msg {
    color: $error-color;
  }

  .success-msg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #00173c;
    margin-top: 2px;
    svg {
      width: 18px;
      height: 18px;
      margin-right: 12px;
      fill: #00173c;
      margin-left: 12px;
      margin-right: 6px;
    }
  }
}

@include mq("tablet-wide") {
  .step-container {
    &.second {
      .unit-details {
        padding: 20px;
        .unit-info {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .address {
            margin: auto;
            text-align: center;
            .navigation-icon {
              margin-right: 7px;
            }
          }

          .size {
            width: auto;
            margin: auto;
            margin-top: 15px;
            text-align: center;
          }

          .move-date {
            width: auto;
            margin: auto;
            text-align: center;
            margin-top: 20px;

            .data-picker-container {
              .label {
                margin: auto;
                margin-bottom: 3px;
              }
            }
          }
        }
      }

      .payment-plan {
        padding: 20px;
        .payment-cards {
          display: flex;
          flex-direction: column;
          margin-top: 17px;
        }
      }
    }
  }
}

@include mq("tablet") {
  .step-container {
    .react-datepicker-popper {
      left: -50px !important;
    }
  }
}
