.custom-select-container {
  min-width: 250px;
  text-align: left;
  position: relative;

  label {
    line-height: 36px;
    font-size: 14px;
    color: #00173c;
  }

  &.error {
    .selected-text {
      border: 2px solid $error-color;
    }

    span {
      position: absolute;
      font-weight: normal;
      font-size: 12px;
      color: $error-color;
    }
  }
}

.selected-text {
  border: 1px solid $input-border;
  height: 40px;
  border-radius: 4px;
  padding: 6px;
  color: $black;
  border: 2px solid $secondary-cyan-blue;
  font-weight: normal;
}

.selected-text::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 51px;
  border: 7px solid transparent;
  border-color: black transparent transparent transparent;
}

ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

.select-options {
  position: absolute;
  width: 100%;
  margin-top: 5px;
  border-radius: 4px;
  box-shadow: $shadow;
  overflow: auto;
  max-height: 250px;
  z-index: 1;
  background: $primary-white;
  &::-webkit-scrollbar {
    width: 8px !important;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 4px !important;
    border-radius: 4px !important;
    background: $primary-white !important;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    background: $secondary-cyan-blue-opacity !important;
  }
}

li {
  position: relative;
  z-index: 1;
  list-style-type: none;
  padding: 18px 20px;
  background: $primary-white;
  color: $secondary-cyan-blue;
  cursor: pointer;
  text-align: left;
  font-family: Graphik;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 150%;
}

li:hover {
  background-color: #f1f3f5;
  color: $secondary-cyan-blue;
}
