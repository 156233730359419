.header {
  min-height: 90px;
  height: 90px;
  background-color: $secondary-cyan-blue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 45px;
  .logo-mobile {
    display: none;
  }

  .sub-logo {
    position: relative;
    margin-left: 15px;
    top: 20px;
  }

  .search {
    position: relative;
    margin-left: 30px;
    input {
      background-color: transparent;
      border: none;
      outline: none;
      border: 1px solid #5c6d88;
      width: 260px;
      height: 40px;
      border-radius: 10px;
      padding-left: 35px;
      padding-right: 30px;
      padding-top: 5px;
      padding-bottom: 5px;
      color: $primary-white;
      @media (min-width: 1025px) and (max-width: 1100px) {
        width: 200px;
      }
      padding-top: 6px;
    }
    input::placeholder {
      @include OpenSans(15px, normal, normal);
      color: $primary-white;
      opacity: 0.45;
    }

    input::-webkit-input-placeholder {
      line-height: normal !important;
    }
    input::-moz-placeholder {
      line-height: normal !important;
    }

    input::-moz-placeholder {
      line-height: normal !important;
    }

    input::-ms-input-placeholder {
      line-height: normal !important;
    }

    input:-moz-placeholder {
      line-height: normal !important;
    }
    .autocomplete-dropdown-container {
      position: absolute;
      z-index: 9999;
      // margin-top: 5px;
      border-radius: 2px;
      box-shadow: $shadow;
      overflow: hidden;
      max-height: 500px;
      width: 250px;
      @media (min-width: 1025px) and (max-width: 1100px) {
        width: 200px;
      }
      @include mq('phablet') {
        width: 190px;
      }
      @include mq('phone') {
        width: 170px;
      }
      .google-logo {
        height: 18px;
        width: 144px;
        background-repeat: no-repeat;
        background-size: 90%;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .suggestion-item,
      .suggestion-item--active {
        background-color: white;
        width: 100%;
        padding: 5px 10px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
        text-align: left;
        font-family: sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        border-top: 1px solid rgba(211, 211, 211, 0.4);
        .main-text {
          color: black;
          font-family: sans-serif;
          font-weight: 500;
          font-style: normal;
        }
        .secondary-text {
          font-family: sans-serif;
          font-weight: 500;
          font-style: normal;
          color: rgb(200, 200, 200);
        }
        &--active {
          // background-color: #f1f3f5;
          // color: $secondary-cyan-blue;
        }
        .suggestion-icon {
          position: absolute;
          top: 5px;
          left: 5px;
          width: 16px;
          height: 16px;
          background-position: 0px 100px;
        }
      }
    }

    .search-icon {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    .navigation {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 6px;
      cursor: pointer;
      path {
        fill: $primary-white;
      }
    }
  }

  .menu-toggle {
    display: none;
  }

  .contact-us {
    display: flex;
    justify-content: space-between;

    .contact {
      display: flex;
      align-items: center;
      margin-left: 40px;

      p {
        font-size: 14px;
        font-weight: 600;
      }
      @include mq('desktop') {
        margin-left: 20px;
      }
      &.faq {
        margin-left: 20px;
        p {
          font-size: 20px;
          margin-left: 0;
        }
      }
    }

    p {
      color: $primary-white;
      @include OpenSans(19.5px, 100%, 500);
      font-weight: bold;
      font-size: 15px;
      margin: auto;
      margin-left: 11px;
      text-align: center;
      white-space: nowrap;
    }
  }
  @include mq('tablet-wide') {
    padding-right: 45px;
    .menu-toggle {
      display: block;
      position: relative;
      .burger-menu {
        width: 20px;
        height: 20px;
        fill: $secondary-red;
        outline: none;
      }
    }
    .desktop {
      display: none;
    }
    .contact-us {
      &.mobile {
        position: fixed;
        padding: 20px;
        background-color: $secondary-cyan-blue;
        display: flex;
        flex-direction: column;
        z-index: 5;
        margin: 0;
        left: 0;
        width: 100%;
        top: 89px;
        .contact,
        .faq {
          margin: 10px 0;
        }
      }
    }

    .sub-logo {
      display: none;
    }
  }

  @include mq('phablet') {
    .search {
      input {
        width: 190px;
      }
    }
  }
  @include mq('phone-wide') {
    padding-left: 0;
    .logo-mobile {
      display: flex;
      height: 100%;
      background-color: $secondary-red;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 5px;
    }

    .search {
      margin-left: 7px;
    }

    .contact-us {
      margin-left: 10px;
    }
  }
  @include mq('phone') {
    padding-right: 25px;
    .search {
      .search-icon {
        display: none;
      }
      input {
        width: 170px;
        padding-left: 15px;
      }
    }
  }
}
