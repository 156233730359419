.step-container {
  width: 100%;
  .about-us-select {
    .select-options {
      height: auto;
      max-height: none;
    }
  }

  &.first {
    padding: 24px 40px 54px;
    margin-top: 15px;
    background-color: $primary-white;
    .title {
      h2 {
        @include Graphik;
        font-weight: bold;
        font-size: 18px;
        color: $secondary-cyan-blue;
        margin: 0;
        padding: 0;
      }
    }
    .booking-form {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .right-side,
      .left-side {
        width: 49%;
      }

      .input,
      .custom-select-container {
        margin-top: 17px;

        label {
          font-weight: normal;
        }
      }

      .name-inputs,
      .adress-inputs {
        display: flex;
        justify-content: space-between;
        .input,
        .custom-select-container {
          min-width: auto;
          width: 48%;
        }
      }
    }
  }
}
