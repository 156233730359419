.carousel-main {
  width: 480px;
  height: 397px;
  align-self: center;
  .carousel__slide {
    background-color: transparent;
  }

  .carousel:first-child {
    border-right: 8px solid transparent;
  }

  .carousel {
    width: 480px;
    height: 300px;
    position: relative;
    overflow: hidden;
    .google-map {
      height: 300px;
    }
    .focusRing___1airF.carousel__slide-focus-ring {
      outline: none !important;
    }
    .carousel__slider--horizontal {
      height: 300px;
    }
    .slide-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .carousel__back-button,
    .carousel__next-button {
      position: absolute;
      top: calc(50% - 20px);
      background-color: transparent;
      border: none;
      outline: none;
    }
    .carousel__back-button {
      left: 0;
    }
    .carousel__next-button {
      right: 0;
    }
  }
  .carousel-group {
    height: 87px;
    margin-top: 8px;
    margin-left: 0;

    .google-map {
      height: 87px;
    }

    .focusRing___1airF.carousel__slide-focus-ring {
      outline: none !important;
    }
    .carousel__slider--horizontal {
      height: 87px;
    }
    .panel-image {
      cursor: pointer;
      max-width: 153px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
    .slide___3-Nqo {
      height: 87px;
    }
    .slide___3-Nqo:first-child {
      .google-map {
        border-right: 6px solid transparent;
      }
    }
  }

  @include mq('tablet-small') {
    width: 100%;
    height: 397px;
    .carousel {
      width: 100%;
      height: 300px;
      .carousel__inner-slide {
        height: 300px;
      }
    }
    .carousel-group {
      width: calc(100% + 8px);
      .panel-image {
        height: 120px;
      }
    }
  }
}
