.gm-style-iw-t {
  .gm-style-iw.gm-style-iw-c {
    height: 41px !important;
    background: $secondary-red;
    padding: 0;
    min-width: 160px !important;
    width: auto !important;

    .gm-style-iw-d {
      overflow: hidden !important;
      .location-name {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .gm-ui-hover-effect {
      display: none !important;
    }
  }

  &:after {
    background: transparent !important;
    width: 0 !important;
    height: 0 !important;
    border-left: 11px solid transparent !important;
    border-right: 11px solid transparent !important;
    transform: translate(-50%, 0) !important;
    box-shadow: none !important;
    border-top: 11px solid $secondary-red !important;
  }
}
.google-map {
  div:first-child {
    div:first-child {
      .gm-style {
        div:nth-child(14) {
          div:first-child {
            height: 50px;
            top: 0;
            width: 40px;
          }
        }
      }
    }
  }
}
