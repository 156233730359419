.input {
  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;

  label {
    display: flex;
    line-height: 36px;
    font-size: 14px;
    color: $secondary-cyan-blue;

    p {
      color: $step-background;
      margin: 0;
      margin-left: 5px;
    }
  }

  input,
  .StripeElement {
    border: 2px solid $secondary-cyan-blue;
    height: 40px;
    padding: 2px 7px;
    @include OpenSans(16px, 22px, normal);
    color: $secondary-cyan-blue;
  }

  .StripeElement {
    padding-top: 9px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  &.error {
    input,
    .StripeElement {
      border: 2px solid $error-color;
    }

    span {
      position: absolute;
      color: $error-color;
      line-height: 21px;
      font-weight: normal;
      font-size: 12px;
      top: 70px;
      margin-top: 7px;
    }
  }
}

input[disabled], input[disabled]:hover { background-color:#e2e2e2; }

.select {
  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;

  label {
    margin-top: 20px;
    display: flex;
    line-height: 36px;
    font-size: 14px;
    color: $secondary-cyan-blue;

    p {
      color: $step-background;
      margin: 0;
      margin-left: 5px;
    }
  }

  select,
  .StripeElement {
    border: 2px solid $secondary-cyan-blue;
    height: 40px;
    padding: 2px 7px;
    @include OpenSans(16px, 22px, normal);
    color: $secondary-cyan-blue;
  }

  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  select::-webkit-outer-spin-button,
  select::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  &.error {
    select,
    .StripeElement {
      border: 2px solid $error-color;
    }

    span {
      position: absolute;
      color: $error-color;
      line-height: 21px;
      font-weight: normal;
      font-size: 12px;
      top: 70px;
      margin-top: 25px;
    }
  }
}

select[disabled], select[disabled]:hover { background-color:#e2e2e2; }