.customer-rewiew-table {
  padding: 50px 170px 0 100px;
  margin-bottom: 90px;
  .rating-mobile {
    display: none;
  }
  .ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
  }

  .ant-table-thead {
    .ant-table-cell {
      @include Graphik(26px, 29px, bold);
      color: $secondary-cyan-blue;
      background-color: $primary-white;
      border-bottom: 2px solid $secondary-cyan-blue;
    }
  }
  .table-head-raiting {
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    p {
      margin-left: 20px;
      font-size: 14px;
    }
  }

  .table-cell {
    text-align: center;
    width: 308px;
    padding-right: 120px;
    box-sizing: border-box;
    h4 {
      color: $secondary-cyan-blue;
      @include OpenSans(18px, 150%, 600);
      margin-bottom: 4px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      @include OpenSans(14px, 150%, normal);
      color: $secondary-cyan-blue;
      p {
        margin-top: 4px;
      }

      .rating-container {
        svg {
          width: 14px;
        }
      }
    }
  }
  @media (min-width: 640px) and (max-width: 730px) {
    .table-head-raiting {
      .rating-container {
        flex-direction: column-reverse;
        margin-bottom: 10px;
        align-items: flex-start;
        .rating-label {
          margin-bottom: 10px;
          margin-left: 0;
        }
      }
    }
  }
}
